.fixed-sidebar-left li.upcoming {
    color: #aaa;
    cursor: default;
    border-left-width: 1px;
    border-left-color: #e3e5e8;
    border-left-style: solid;
    padding: 7px 15px 7px 28px;
    margin-left: 32px;
    width: calc(250px - 32px) !important;
}
.fixed-sidebar-left li.upcoming i {
    cursor: pointer;
    font-size: 1rem;
    width: 1rem;
}