body{
    font-family: 'Kosugi Maru',"Roboto", sans-serif;
}
a {
    color: #ff008c;
    transition: color .2s;
}
a:hover {
    color: #cc0070;
}
.footer {
    font-size: 12px;
    height: 36px;
}
.text-primary,
.txt-red{
    color: #ff008c !important;
}
.panel-primary hr {
    border-top: 1px solid #ff008c;
}
.panel-success hr{
    border-top: 1px solid #999;
}
.panel-danger hr{
    border-top: 1px solid #f5b848;
}
.btn.disabled,
.btn:disabled {
    opacity: .4;
}
.btn.btn-primary{
    background-color: #ff008c;
    color: #FFF;
    border: solid 1px #ff008c;
}
.btn.btn-primary:focus{
    background-color: transparent;
    color: #ff008c !important;
    border: solid 1px #ff008c;
}
.btn.btn-primary:active{
    background-color: transparent;
    color: #ff008c;
    border: solid 1px #ff008c;
}
.btn.btn-primary:hover{
    background-color: #ff008c;
    border: solid 1px #ff008c;
    color: #fff !important;
}
.btn.btn-outline.btn-primary{
    color: #ff008c;
    background-color: transparent;
    border: solid 1px #ff008c;
}
.btn.btn-outline.btn-primary:hover{
    background-color: #ff008c;
}
.btn.btn-outline.btn-primary:disabled,
.btn.btn-outline.btn-primary:disabled:hover{
    color: #888 !important;
    background-color: transparent;
    border: solid 1px #888;
}
.btn.btn-outline.btn-primary:focus,.btn.btn-outline.btn-primary:active{
    color: #ff008c;
}
.dtp div.dtp-date, .dtp div.dtp-time {
    background-color: #949494 !important;
}
.dtp .p10 > a{
    color: #000 !important;
}
.dtp table.dtp-picker-days tr > td > a.selected{
    background-color: #949494 !important;
}
.dtp > .dtp-content > .dtp-date-view > header.dtp-header{
    background-color: #333 !important;
}
.dtp div.dtp-actual-year {
    color: #ddd !important;
}

.download-icon{
    display: block;
    border-radius: 15px;
    background: url(/dist/img/download.png) center center no-repeat;
    background-size: 12px 12px;
    background-color: #29323d;
    width: 20px;
    height: 20px;
}
.reactDataTable {
    overflow: visible !important;
}
.reactDataTable > div {
    display: block;
}
.rdt_TableRow{
    border: 0px;
}

.rdt_TableCell{
    padding: 18px 8px !important;
    display: table-cell;
    border: 0px;
    min-width: 50px !important;
    font-size: 14px;
    text-align: center;
}
.rdt_TableRow{
    border: 0px !important;
}

.rdt_TableRow .rdt_TableCell:last-child{
    display: inline;
    text-align: center;
    min-width: 50px !important;
    text-align: center;
}
.rdt_TableRow:nth-child(odd) .rdt_TableCell:first-child{
    border-radius: 8px;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    min-width: 50px !important;
    text-align: center;
}
.rdt_TableRow:nth-child(odd) .rdt_TableCell:last-child{
    border-radius: 8px;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    text-align: center;
}
.rdt_TableRow:nth-child(odd) .rdt_TableCell{
    min-width: 50px !important;
    background-color: #fff!important;
    box-shadow: 0 2px 2px 0 rgba(29,29,31,0.1);
    overflow: hidden;
    text-align: center;
}

.rdt_TableFooter{
    overflow: hidden;
    border: 0px !important;
    margin-top: 0;
}
.rdt_TableHead{
    border: 0px;
}
.rdt_TableHeadRow{
    border: 0px !important;
}
.rdt_TableHeadRow .rdt_TableCol{
    padding: 20px 8px;
    text-align: center;
}
.rdt_TableHeadRow .rdt_TableCol .rdt_TableCol_Sortable{
    font-size: 0.86rem;
    color: #29323d;
    font-weight: bold;
}
.rdt_TableHeadRow .rdt_TableCol:last-child{
    text-align: center;
    display: inline;
}
.noData{
    width: 100%;
}

/*Rakuten*/
body{
    background: #f5f5f5;
}
.btn.btn-info{
    background: #ff008c;
    border: 1px solid #ff008c;
    width: 100%;
    border-radius: 6px;
}
.btn.btn-info:hover{
    background: #ff008c;
    border: 1px solid #ff008c;
    opacity: 0.8;
}
.btn.btn-link {
    color: #ff008c;
    text-decoration: none;
}
.btn.btn-link:hover {
    color: #cc0070;
}
.fixed-sidebar-left{
    padding-top: 76px;
    background: #ededed;
    z-index: 1;
}
.page-wrapper {
    z-index: 0;
}
.wrapper.theme-active .fixed-sidebar-left .side-nav{
    background: #ededed;
}
.wrapper.theme-active .fixed-sidebar-left .side-nav > li > a.active,
.wrapper.theme-active .fixed-sidebar-left .side-nav > li a[aria-expanded=true] {
    color: #313558;
}
.wrapper.theme-active .fixed-sidebar-left .side-nav > li a[aria-expanded=true] {
    background: transparent;
}
.wrapper.theme-active .fixed-sidebar-left .side-nav > li > a.active {
    background: #d1d5d9;
}
.fixed-sidebar-left .side-nav li a[aria-expanded=true] .float-right i {
    color: #768093;
}
.wrapper.theme-active .fixed-sidebar-left .side-nav li a{
    color: #313558;
}
.wrapper.theme-active .fixed-sidebar-left .side-nav li a:hover{
    color: #313558;
    border-left: 1px solid #ff008c;
}
.wrapper.theme-active .fixed-sidebar-left .side-nav>li>a.active:hover, .wrapper.theme-active .fixed-sidebar-left .side-nav>li>a:hover{
    border-left: 1px solid #d1d5d9;
}
.fixed-sidebar-left .side-nav>li ul.collapse li a.active{
    border-left: 1px solid #ff008c;
    color: #ff008c;
}
.title-hr h5{
    border-bottom: 2px solid #ff008c;
}
.page-link {
    transition: background-color .2s;
}
.page-link:not(:disabled) {
    cursor: pointer;
}
.page-link,
.page-link:hover {
    color: #ff008c;
}

.page-link:disabled {
    cursor: default;
    opacity: .5;
}
.cursor-pointer {
    cursor: pointer;
}
.px-20 {
    padding-left: 20px;
    padding-right: 20px;
}
.ws-nowrap {
    white-space: nowrap;
}

/* Nav Tabs */

.wrapper .nav-tabs > li.nav-item.active > a,
.wrapper .nav-tabs > li.nav-item > a.nav-link:active,
.wrapper .nav-tabs > li.nav-item > a.nav-link:focus,
.wrapper .nav-tabs > li.nav-item > a.nav-link:hover {
    color: #ff008c;
}
.wrapper .nav-tabs > li.nav-item.active > a:after {
    border-color: #ff008c;
}

/* Form */

.form-control.is-invalid,
.was-validated .form-control:invalid {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' fill='none' stroke='%23dc3545' viewBox='0 0 12 12'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23dc3545' stroke='none'/%3e%3c/svg%3e");
}