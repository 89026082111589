.logo-wrap {
    width: 100%;
}
.logo-wrap a {
    display: block;
    padding-left: 10px;
    padding-right: 10px;
}
.logo-wrap .brand-text {
    font-size: 14px;
    font-weight: normal;
}
.logo-wrap .brand-img.large {
    height: auto;
    width: 200px;
}
.logo-wrap .brand-img.small {
    height: 40px;
    width: auto;
}
@media (min-width: 1201px) {
    .logo-wrap .brand-img.small {
        display: none;
    }
    .slide-nav-toggle .logo-wrap .brand-img.large {
        display: none;
    }
    .slide-nav-toggle .logo-wrap .brand-img.small {
        display: block;
    }
}
@media (min-width: 1025px) and (max-width: 1200px) {
    .logo-wrap .brand-img.large {
        display: none;
    }
    .logo-wrap .brand-img.small {
        display: inline-block;
    }
    .slide-nav-toggle .logo-wrap .brand-img.large {
        display: inline-block;
    }
    .slide-nav-toggle .logo-wrap .brand-img.small,
    .slide-nav-toggle .logo-wrap .brand-text {
        display: none;
    }
}
@media (min-width: 1025px) {
    .logo-wrap .brand-img.large {
        margin-top: .8rem;
    }
    .logo-wrap .brand-img.small {
        top: 13px;
    }
}
@media (max-width: 1024px) {
    .logo-wrap .brand-img.large {
        display: block;
    }
    .logo-wrap .brand-img.small {
        display: none;
    }
}
@media (min-width: 768px) and (max-width: 1024px) {
    .mobile-only-brand {
        left: 50%;
        transform: translateX(-50%)
    }
}
@media (max-width: 767px) {
    .mobile-only-brand {
        left: 50px;
    }
}
@media (max-width: 500px) {
    .logo-wrap .brand-img.large {
        display: none;
    }
    .logo-wrap .brand-img.small {
        display: block;
        height: 30px;
    }
}