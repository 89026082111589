.searchBar {
    align-items: stretch;
    display: grid;
    grid-template-columns: auto 1fr auto;
}
.searchBar label {
    align-items: center;
    background-color: #dedede;
    border: 1px solid #d0d0d0;
    border-radius: 8px 0 0 8px;
    display: flex;
    padding: 0 1rem;
}
.searchBar .btn {
    border-radius: 0 8px 8px 0;
}
.searchBar .selectize-control .selectize-input {
    border-width: 1px 0;
    border-radius: 0;
    box-shadow: none;
    height: 100%;
    margin-bottom: 0;
    vertical-align: middle;
}
.searchBar .selectize-control .selectize-input input {
    font-size: 1rem;
    line-height: 1;
}
.searchBar .selectize-dropdown .active {
    background-color: rgba(255, 0, 140, .1);
    padding: 5px;
}
.selectedMeters .badge {
    align-items: center;
    display: inline-flex;
    padding-right: 5px;
}
.selectedMeters .badge i.material-icons {
    background: transparent;
    border-radius: 2px;
    color: #777;
    cursor: pointer;
    margin-left: 5px;
    transition-duration: .2s;
    transition-property: background-color, color;
}
.selectedMeters .badge i.material-icons:hover {
    background: #eee;
    color: #000;
}